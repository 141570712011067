<template>
  <div class="login">
    <v-row no-gutters>

      <v-col cols="12" md="7" class="login-bg mobile-hidden">

        <div class="loginText">

          <div class="text-center">
            <img src="https://ik.imagekit.io/soocel/wp/soocel-spotlight_XqMAWdz-X.png" />
          </div>

          <h1 class="mt-3">Welcome to Soocel</h1>
          <p>At Soocel, we understand the power of influencer marketing and how it can help businesses reach new audiences and grow their brand. With our help, you can tap into the influence and reach of top social media influencers to drive engagement and boost sales for your business. </p>

          <p class="mt-6 mb-2"><strong>Our USP</strong></p>

          <ul>
            <li>More than 50K Influencers across social media platforms</li>
            <li>Low CPI for campaigns</li>
          </ul>

          <div class="text-center mt-8 desktop-hidden">
            <v-btn depressed block color="white" @click="showInfo = false">Continue</v-btn>
          </div>

        </div>

      </v-col>

      <v-col cols="12" md="5">
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Client Registration</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          ref="addInfo"
          v-on:submit.prevent="onSubmit"
        >
          <v-alert
            outlined
            type="error"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <div v-if="step === 1">
            <label class="label">Email</label>
            <v-text-field
              v-model="user.email"
              :rules="addInfoRules.email"
              placeholder="Enter email address"
              solo
            ></v-text-field>

            <label class="label">Password</label>
            <v-text-field
              v-model="user.password"
              :rules="addInfoRules.password"
              type="password"
              placeholder="Set a password"
              solo
            ></v-text-field>

            <label class="label">Confirm Password</label>
            <v-text-field
              v-model="user.confirmPassword"
              :rules="addInfoRules.confirmPassword"
              type="password"
              placeholder="Confirm your password"
              solo
            ></v-text-field>
          </div>

          <div v-if="step === 2">
            <label class="label">Name</label>
            <v-text-field
              v-model="user.name"
              :rules="addInfoRules.name"
              placeholder="Enter your name"
              solo
            ></v-text-field>

            <label class="label">Company Name</label>
            <v-text-field
              v-model="user.companyName"
              :rules="addInfoRules.companyName"
              placeholder="Enter your company name"
              solo
            ></v-text-field>

            <label class="label">Company Website/URL</label>
            <v-text-field
              v-model="user.url"
              :rules="addInfoRules.companyUrl"
              placeholder="Enter Company Website/URL"
              solo
            ></v-text-field>
          </div>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            @click="updateInfo()"
          >Update</v-btn>

        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { updateProfile, getUserInfo } from '@/api/user'

export default {
  data () {
    return {
      loading: false,
      valid: true,
      showInfo: true,
      step: Number(this.$route.query.step) || 1,
      user: JSON.parse(window.localStorage.getItem('user')),
      alertMsg: '',
      alert: false,
      addInfoRules: {
        companyUrl: [
          v => !!v || 'Company site url required',
          v => /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/gi.test(v) || 'Invalid Company link'
        ],
        name: [
          v => !!v || 'Name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid name'
        ],
        companyName: [
          v => !!v || 'Company name required'
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail'
        ],
        password: [
          v => !!v || 'Password is required'
        ],
        confirmPassword: [
          v => !!v || 'Password is required',
          v => v === this.user.password || 'Password not match'
        ]
      }
    }
  },
  methods: {
    async updateInfo () {
      if (this.$refs.addInfo.validate()) {
        try {
          this.loading = true
          const data = await updateProfile(this.user)
          if (data.success) {
            const response = await getUserInfo()
            const localData = {
              id: response.data.client_id,
              mobile: response.data.mobile,
              status: response.data.status,
              email: response.data.email,
              name: response.data.name
            }
            window.localStorage.setItem('user', JSON.stringify(localData))
            this.user = response.data
            if (this.user.name) {
              this.$router.push('/dashboard')
            } else {
              this.step = 2
              this.loading = false
            }
          } else {
            this.loading = false
            this.alertMsg = data.message
            this.alert = true
          }
        } catch (error) {
          this.loading = false
          this.alertMsg = 'Something went wrong'
          this.alert = true
        }
      } else {
        console.log('error submit!!')
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
